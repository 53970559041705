.editable-cell[data-v-60d00cbc]{
  position:relative
}
.editable-cell-value-wrap[data-v-60d00cbc]{
  padding:5px 12px;
  cursor:pointer
}
[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap[data-v-60d00cbc]{
  border:1px solid #434343
}
.editable-cell-container[data-v-60d00cbc]{
  display:flex;
  position:relative
}
.editable-cell-header[data-v-60d00cbc]{
  display:flex;
  justify-content:left;
  align-items:center
}
.editable-cell-header span[data-v-60d00cbc]{
  margin-right:6px
}
