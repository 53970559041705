.container[data-v-0f04c444]{
  width:100%;
  background-color:white;
  border-radius:6px;
  position:relative;
  margin-top:12px
}
.title[data-v-0f04c444]{
  display:inline-block;
  margin-bottom:0;
  color:rgba(0,0,0,0.85);
  font-weight:600;
  font-size:24px;
  line-height:1.35
}
.header[data-v-0f04c444]{
  display:flex;
  align-items:center
}
.content[data-v-0f04c444]{
  padding:24px;
  padding-top:0
}
.title-data[data-v-0f04c444]{
  background:rgba(32,165,122,0.74);
  color:#fff;
  border-radius:3px;
  box-shadow:0 0 10px rgba(0,0,100,0.2);
  padding:0 0.86rem;
  font-weight:700;
  font-size:1.2rem
}
