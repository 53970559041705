.app {
  color: aqua;
}

.powered-by {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px;
  width: 100%;
  text-align: center;
}

.ant-layout-footer .powered-by {
  text-align: right;
}

.mt-16 {
  margin-top: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

.w-fill {
  width: 100% !important;
}

.h-fill {
  height: 100vh !important;
}

.icon-space {
  margin-right: 6px;
}

.whole-screen {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.hide-requered-before .ant-form-item-required::before {
  content: none !important;
}

.p-cap {
  text-transform: capitalize;
}

.form-label-left .ant-form-item-label {
  text-align: left;
}

.ant-menu-inline .ant-menu-item {
  margin: 0 !important;
}

.form_modal .ant-modal-body {
  padding-bottom: 0;
}

.global-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.align-center {
  display: flex;
  align-items: center;
}

.ant-table-cell {
  padding: 6px 16px !important;
}

.ant-table-column-sorters {
  padding: 6px 16px !important;
  margin: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #373f51;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #373f51;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #373f51;
}

.vb__utils__heading {
  position: relative;
  padding-left: 2rem;
  font-size: 1.13rem;
  color: #141322;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 16px;
}

.vb__utils__heading::before {
  position: absolute;
  top: 0.33rem;
  left: 0;
  content: '';
  display: block;
  float: left;
  height: 1rem;
  width: 1rem;
  background: #1da57a;
  border-radius: 4px;
}
