.wi-item[data-v-de10f62d]{
  position:relative;
  padding:12px;
  border-radius:2px;
  cursor:pointer;
  color:white;
  box-shadow:1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, inset 1px 0 0 0 #f0f0f0, inset 0 1px 0 0 #f0f0f0;
  float:left;
  transition:all 0.3s
}
.wi-item[data-v-de10f62d]:hover{
  box-shadow:0 1px 2px -2px rgba(0,0,0,0.16),0 3px 6px 0 rgba(0,0,0,0.12),0 5px 12px 4px rgba(0,0,0,0.09);
  position:relative;
  z-index:1
}
.edit-box[data-v-de10f62d]{
  position:absolute;
  display:flex;
  align-items:center;
  justify-content:center;
  opacity:0;
  width:0;
  height:100%;
  background-color:rgba(0,0,0,0.5);
  top:0;
  right:0;
  z-index:1;
  border-top-right-radius:12px;
  border-bottom-right-radius:12px;
  transition:all 0.5s ease
}
.wi-item:hover .edit-box[data-v-de10f62d]{
  width:50px;
  opacity:1
}
.active[data-v-de10f62d]{
  border-bottom:4px solid #2d6955
}
